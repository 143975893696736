import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import useResponsiveImage from '../../hooks/useResponsiveImage';
import buildCdnUrl from '../../helpers/cdn';

const LazyImage = ({
  imagePath,
  alt,
  width,
  format = 'webp',
  quality = 100,
}) => {
  const { width: resolvedWidth } = useResponsiveImage(width);

  const imageUrl = buildCdnUrl(imagePath, {
    width: resolvedWidth,
    format,
    quality,
  });

  return (
    <LazyLoadImage
      alt={alt}
      src={imageUrl}
      effect="blur"
      style={{ width: '100%', height: 'auto' }}
    />
  );
};

LazyImage.propTypes = {
  imagePath: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  format: PropTypes.string,
  quality: PropTypes.number,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      mobile: PropTypes.number,
      tablet: PropTypes.number,
      desktop: PropTypes.number,
      large: PropTypes.number,
    }),
  ]).isRequired,
};

LazyImage.defaultProps = {
  format: 'webp',
  quality: 100,
};

export default LazyImage;
