import styled from '@mui/material/styles/styled';
import { ValidationTextFieldInput } from '.';

const RegisterTextField = styled(ValidationTextFieldInput)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    height: 30,
  },
  '& .MuiFilledInput-root': {
    height: 50,
  },
}));

export default RegisterTextField;
