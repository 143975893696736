import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import PropTypes from 'prop-types';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const LazyLoadImage = ({ src, alt, placeholder, ...props }) => {
  if (!src) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f0f0f0',
        }}
        {...props}
      >
        <QuestionMarkIcon color="#FFFFFF" fontSize="10px" />
      </Box>
    );
  }

  return (
    <ProgressiveImage src={src} placeholder={placeholder}>
      {(newSrc, loading) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden',
            margin: '0px auto',
          }}
          {...props}
        >
          {loading ? (
            <CircularProgress
              id="progressive-image-loading"
              title="ProgressiveImage loading"
              color="gray"
            />
          ) : (
            <Box
              component="img"
              src={newSrc}
              alt={alt}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          )}
        </Box>
      )}
    </ProgressiveImage>
  );
};

LazyLoadImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  placeholder: PropTypes.string,
};

LazyLoadImage.defaultProps = {
  src: null,
  alt: '',
  placeholder: '',
};

export default React.memo(LazyLoadImage);
